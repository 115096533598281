.inputContainer {
    margin-bottom: 1rem;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
}

.input-field,
.button {
    padding: 6px;
    font-size: 16px;
    border-radius: 5px;
    margin-right: 20px;
}

.input-field {
    width: 275px;
    background-color: rgb(233, 240, 253);
}

.button {
    background-color: rgb(207, 207, 207);
}

select {
    margin-left: -2px;
}

.buttonContainerWrapper {
    margin: 0 auto;
}

.signup-form-text {
    margin-top: 20px;
    color: rgb(22, 33, 62);
    background-color: #fff;
    border-radius: 10px;
    padding: 0px 30px 20px 30px;
}
