.footerContainer {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerSubscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
}

.footerSubscription > p {
    font-family: 'Josefin Sans', sans-serif;
}

.footerSubscriptionHeading {
    margin-bottom: 24px;
    max-width: 90vw;
    font-size: 24px;
    color: #fff;
}

.footerSubscriptionText {
    margin-bottom: 24px;
    font-size: 20px;
    color: #fff;
}

.inputAreas {
    margin-top: -15px;
}

.footerInput {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footerLinks {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footerLinkWrapper {
    display: flex;
}

.footerLinkItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footerLinkItems h2 {
    margin-bottom: 16px;
}

.footerLinkItems > h2 {
    color: #fff;
}

.footerLinkItems a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    transition: 0.3s ease-out;
}

.footerLinkItems a:hover {
    color: tomato;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footerInput::placeholder {
    color: #b1b1b1;
}

.socialIconLink {
    color: #fff;
    font-size: 24px;
    transition: 0.3s ease-out;
}

.socialIconLink:hover {
    color: tomato;
    transition: 0.3s ease-out;
}

.socialMedia {
    max-width: 1000px;
    width: 100%;
}

.socialMediaWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.socialIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
}

.socialLogo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Racing Sans One', cursive;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    transition: 0.3s ease-out;
}

.socialLogo:hover {
    color:tomato;
    transition: 0.3s ease-out;
}

.websiteRights {
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
    .footerLinks {
        padding-top: 2rem;
    }

    .footerInput {
        width: 100%;
    }

    .footerLinkWrapper {
        flex-direction: column;
    }

    .socialMediaWrap {
        flex-direction: column;
    }

    .footerSubscriptionHeading {
        font-size: 20px;
        width: 90vw;
    }
}

@media screen and (max-width: 600px) {
    .inputAreas {
        margin-top: 20px;
    }
}

@media screen and (max-width: 500px) {
    .footerInput {
        margin: 0 0 12px 0;
    }

    .footerLinkItems {
        margin: 0;
    }

    #right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}


