* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
}

iframe {
  width: 100%;
  height: 100vh;
}

.form-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  padding-top: 30px;
  background: linear-gradient(
  90deg,
  rgb(26, 23, 23) 0%,
  rgb(28, 27, 27) 100%
  );
}

.form-container > h1 {
  color: #fff;
  font-size: 45px;
}
