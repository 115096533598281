.searchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px auto 0 auto;
}

.myLocation {
    min-width: 130px !important;
    margin: 0 4px !important;
    padding: 6px;
    border-radius: 5px;
    height: 40px !important;
    font-size: 12px !important;
}

.sbInputs {
    margin: 5px;
    padding: 6px;
    font-size: 16px;
    background-color: rgb(207, 207, 207);
    border-radius: 5px;
    height: 40px !important;
    font-size: 12px !important;
}

@media screen and (max-width: 960px) {
    .sbInputs,
    .myLocation {
        height: 35px !important;
    }
}