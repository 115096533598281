.video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.mobileBackground {
    object-fit: fill;
    margin-top: 140px;
    width: 100%;
    position: fixed;
    z-index: -1;
}

.heroContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.heroLogo {
    color: #fff;
    font-family: 'Racing Sans One', cursive;
    font-size: 120px;
    margin-top: 240px;
    text-decoration: none;
    transition: all 0.2s ease-in;
}

.heroLogo:hover {
    color: tomato;
    font-size: 122px;
    transition: all 0.2s ease-in;
}

.heroContainer > h2 {
    color: #fff;
    font-size: 32px;
    transition: all 0.2s ease-in;
}

.slogan {
    margin: 8px 0;
    color: #fff;
    font-size: 25px;
    font-family: 'Josefin Sans', sans-serif;
}

.heroBtns {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
}

.heroBtns .btn {
    margin: 6px;
}

.heroLogo {
    animation: transitionIn 2s;
}

.heroContainer > h2 {
    animation: transitionIn 3s;
}

.slogan {
    animation: transitionIn 4s;
}

.heroContainer > .heroBtns {
    animation: transitionIn 5s;
}


@keyframes transitionIn {
    from {
        opacity: 0;
        transform: rotateX(-10deg);
    }

    to {
        opacity: 1;
        transform: rotateX(0);
    }

}

@media screen and (max-width: 960px) {
    .heroLogo {
        font-size: 90px;
        transition: all 0.2s ease-in;
    }

    .heroLogo:hover {
        font-size: 92px;
        transition: all 0.2s ease-in;
    }
    
    .heroContainer > h2 {
        font-size: 24px;
        transition: all 0.2s ease-in;
    }
}

@media screen and (max-width: 768px) {
    .heroLogo {
        font-size: 70px;
    }

    .heroLogo:hover {
        font-size: 72px;
        transition: all 0.2s ease-in;
    }
    
    .heroContainer > h2 {
        font-size: 19px;
    }
    
}

@media screen and (max-width: 500px) {
    .heroLogo {
        font-size: 50px;
        margin-top: 30vh;
    }

    .heroLogo:hover {
        font-size: 52px;
        transition: all 0.2s ease-in;
    }

    .heroContainer > h2 {
        font-size: 13px;
    }

    .heroBtns {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
    }
    
    .heroBtns .btn {
        margin: 0 0 14px 0;
        min-width: 60vw;
    }

    .heroLogo {
        animation: none;
    }
    
    .heroContainer > h2 {
        animation: none;
    }
    
    .slogan {
        animation: none;
    }
    
    .heroContainer > .heroBtns {
        animation: none;
    }

}

@media screen and (max-height: 670px) {
    .mobileBackground {
        margin-top: 300px;
    }

    .heroLogo {
        animation: none;
        margin-top: 300px;
    }
}