.searchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.sbInputs {
    margin: 5px;
    margin-top: 8px;
    padding: 6px;
    font-size: 16px;
    background-color: rgb(207, 207, 207);
    border-radius: 5px;
    height: 40px !important;
    font-size: 12px !important;
}

@media screen and (max-width: 960px) {
    .sbInputs {
        height: 35px !important;
    }

    .searchBar {
        padding-bottom: 5px;
    }
}
