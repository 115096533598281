.inputField {
    padding: 9px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-family: 'PT Sans', sans-serif;
    font-size: 19px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.primaryBtn {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    padding: 8px 20px;
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
    /* font-weight: 500; */
    border-radius: 2px;
    cursor: pointer;
}

.primaryBtn:hover {
    color: tomato;
    background: #fff;
    transition: all 0.3s ease-out;
}

.mc__form_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* margin-top: -10px; */
    height: 80px;
    /* background-color: black; */
}

.mc__alert{
    color: #fff;
}

@media screen and (max-width: 600px) {

    .mc__alert{
        margin: 15px 0;
    }

    .mc__field_container {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
    }

}

@media screen and (max-width: 420px) {

    .mc__field_container {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
    }

    /* .primaryBtn {
        height: 45px;
        width: 130px;
        margin-top: 10px;
        margin-left: 35px;
    } */

    /* .inputField {
        height: 45px;
        width: 90vw;
        margin-left: 35px;
    } */

    .mc__alert{
        margin: 15px 0;
        width: 60vw;
    }

}

